<template>
     <KCourse loader-class="MBcont" course-id="0" title="3 Шага к красивой кукле" :items="items">
        <div class="container">
            <iframe class="video" src="https://www.youtube.com/embed/O-_hqRxeulM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>

        <div class="MBtextcont">
            <h3>Глаза</h3>
            <p><b>1. Глаз — это сфера</b></p>
        </div>
        
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/10.1.jpg')" alt="" width="100%"/></div>
        <div class="MBtextcont">
            <p><b>2. Глаз располагается в черепе так, чтобы быть защищенным от ударов</b> (т.е. надбровные дуги и переносица выступают больше, чем глаз)</p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/11.jpg')" alt="" width="100%"/></div>
        
        <div class="MBtextcont">
            <p><b>3. Верхнее и нижнее веко повторяют форму глаза (сферу).</b> Веко — тонкая кожа, которая огибает глаз</p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/12.jpg')" alt="" width="100%"/></div>
        
        <div class="MBtextcont">
            <p class="nomargin"><b>4. Радужка глаза оголена взависимости от настроения человека</b></p>
            <ul>
                <li>Обычное состояние. Радужка слегка прикрыта сверху</li>
                <li>Томное настроение. Верхнее веко покрывает радужка до зрачка</li>
                <li>Улыбка. Верхнее веко на границе радужки, нижнее прямое</li>
                <li>Удивление, испуг. Полностью видна радужка</li>
            </ul>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/13.jpg')" alt="" width="100%"/></div>
        
        <div class="MBtextcont">
            <p class="nomargin"><b>5. Уголки глаз симметричны</b></p>
            <ul>
                <li>Внешние уголки стоят на одной линии</li>
                <li>Внутренние уголки стоят на одной линии</li>
            </ul>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/14.jpg')" alt="" width="100%"/></div>
        
        <div class="MBtextcont">
            <p><b>6. Надбровная дуга имеет объемы! </b></p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/15.png')" alt="" width="100%"/></div>
       
        <div class="MBtextcont">
            <h2>НОС И ГУБЫ</h2>
            <p><b>1.  Проведите "рельсы"</b> через уголки глаз и крылья носа. Рельсы — это самая выступающая часть лица (переносица, нос, губы, подбородок) к ним стремится все лицо</p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/16.jpg')" alt="" width="100%"/></div>
        
        <div class="MBtextcont">
            <p><b>2. Нос — это трапеция</b></p>
        </div>
        <div class="KImage" :class="$mq"><KImage :src="require('@/assets/membersh/FaceFree/17.jpg')" alt="" width="100%"/></div>
        
        <Paragrath 
            pNoMargin
            :imgSrc="require('@/assets/membersh/FaceFree/18.jpg')"
            :liItems="['Верхняя губа — это 2 лепестка по бокам и шарик посередине','Нижняя губа — это 2 лепестка']"
        >
            <b>3. Диагонали от центра носа к подбородку</b> покажут основные объемы губ
        </Paragrath>

        <Paragrath :imgSrc="require('@/assets/membersh/FaceFree/19.jpg')">
            <b>4. Угол между носом и губами 90 градусов.</b><br>
            <b>5. Соблюдайте правило 4-ех точек </b>(нос, губы, подбородок на одной наклонной)
        </Paragrath>
        <Paragrath :imgSrc="require('@/assets/membersh/FaceFree/20.jpg')">
            <b>7. Челюсти имеют объем!</b>
        </Paragrath>
     </KCourse>
</template>

<script>
    import KCourse from "../../../Widgets/KCourse";
    import KImage from "../../../Widgets/KImage";
    import Paragrath from "./Paragraph"
    export default {
        name: "MBCourseHead",
        components: {
            KCourse,
            KImage,
            Paragrath
        },
        data() {
        return {
            items: [
                { title: " Пропорции", url:"/mycourses/facemistakes/1"},
                { title: " Детали", url:"/mycourses/facemistakes/2"},
                { title: " Разбор", url:"/mycourses/facemistakes/3"},
            ],
        }
        }
    }
</script>

<style scoped>
    .KImage{ 
        width: 80%;
        margin: 0 auto 50px auto;
        display: block;
    }    .KImage.mobile, .KImage.laptop{
             width:100%
         }
    
    .container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        box-sizing: border-box;
        margin-bottom: 1em;
    }
    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>